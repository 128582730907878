<template>
	<v-layout wrap class="pui-form-layout">
		<v-flex xs12 md12>
			<pui-field-set :title="getTitle">
				<v-layout wrap>
					<v-flex xs12 md7>
						<notificacion-form-implicado
							:model="parentModel.implicadocn"
							:formDisabled="formDisabled"
							:idpuertolocal="parentModel.idpuertolocal"
							templatename="implicadocn"
						></notificacion-form-implicado>
					</v-flex>
				</v-layout>
				<v-layout wrap>
					<v-flex xs12 md6 >
						<pui-field-set v-if="visibleLayoutConsignatarioentrada" :title="$t('notificacion.implicados.consignatarioentrada')">
							<v-layout wrap>
								<notificacion-form-implicado
									:model="parentModel.implicadocvent"
									:formDisabled="formDisabled"
									:idpuertolocal="parentModel.idpuertolocal"
									templatename="implicadocvent"
								></notificacion-form-implicado>
							</v-layout>
						</pui-field-set>
					</v-flex>
					<v-flex xs12 md6>
						<pui-field-set v-if="visibleLayoutConsignatariosalida" :title="$t('notificacion.implicados.consignatariosalida')">
							<v-layout wrap>
								<notificacion-form-implicado
									:model="parentModel.implicadocvsal"
									:formDisabled="formDisabled"
									:idpuertolocal="parentModel.idpuertolocal"
									templatename="implicadocvsal"
								></notificacion-form-implicado>
							</v-layout>
						</pui-field-set>
					</v-flex>
				</v-layout>
				<v-layout wrap> 
					<v-flex xs12 md6>
						<pui-field-set v-if="visibleLayoutEstibadordescarga" :title="$t('notificacion.implicados.estibadordescarga')">
							<v-layout wrap>
								<notificacion-form-implicado-estibador
									:model="parentModel.implicadotrent"
									:formDisabled="formDisabled"
									:idpuertolocal="parentModel.idpuertolocal"
									templatename="implicadotrent"
								></notificacion-form-implicado-estibador>
								
							</v-layout>
						</pui-field-set>
					</v-flex>
					<v-flex xs12 md6 >
						<pui-field-set v-if="visibleLayoutEstibadorcarga" :title="$t('notificacion.implicados.estibadorcarga')">
							<v-layout wrap>
								<v-flex xs12 md12>									
									<v-layout wrap>
										<notificacion-form-implicado-estibador
											:model="parentModel.implicadotrsal"
											:formDisabled="formDisabled"
											:idpuertolocal="parentModel.idpuertolocal"
											templatename="implicadotrsal"
										></notificacion-form-implicado-estibador>
									</v-layout>								
								</v-flex>
							</v-layout>
						</pui-field-set>
					</v-flex>
				</v-layout>
			</pui-field-set>
		</v-flex>
	</v-layout>
</template>

<script>
import notificacionFormImplicado from './NotificacionFormImplicado.vue';
import notificacionFormImplicadoEstibador from './NotificacionFormImplicadoEstibador.vue';

export default {
	name: 'notificacion-form-tab-implicados',
	components: {
		'notificacionFormImplicado': notificacionFormImplicado,
		'notificacionFormImplicadoEstibador': notificacionFormImplicadoEstibador
	},
	data() {
		return {
			modelName: 'notificacion'
		};
	},
	props: {
		parentModel: {
			type: Object,
			required: true
		},
		formDisabled: {
			type: Boolean,
			required: true
		}
	},
	computed: {
		getTitle() {
			return this.$t('notificacion.implicados.title');
		},
		fixedFilterContacto() {
			return {
				groups:[
					{
						groupOp: 'and',
						rules: [{ field: 'idpuertolocal', op: 'eq', data: this.parentModel.idpuertolocal }]
					}
				],
				groupOp: 'and',
				rules: []
			};
		},
		visibleLayoutConsignatarioentrada() {
			if(this.parentModel.idtipoman === 1 
				|| this.parentModel.idtipoman === 3
				|| this.parentModel.idtipoman === 4
				|| this.parentModel.idtipoman === 5){
				
				return true;
			}
			return false;
		},
		visibleLayoutConsignatariosalida() {
			if(this.parentModel.idtipoman === 2 
				|| this.parentModel.idtipoman === 3
				|| this.parentModel.idtipoman === 4
				|| this.parentModel.idtipoman === 5){
				
				return true;
			}
			return false;
		},
		visibleLayoutEstibadordescarga() {
			if(this.parentModel.idtipoman === 1 
				|| this.parentModel.idtipoman === 3
				|| this.parentModel.idtipoman === 4
				|| this.parentModel.idtipoman === 5
				|| this.parentModel.idtipoman === 6
				|| this.parentModel.idtipoman === 7
				|| this.parentModel.idtipoman === 9){
				
				return true;
			}
			return false;
		},
		visibleLayoutEstibadorcarga() {
			if(this.parentModel.idtipoman === 2
				|| this.parentModel.idtipoman === 3
				|| this.parentModel.idtipoman === 4
				|| this.parentModel.idtipoman === 5
				|| this.parentModel.idtipoman === 6
				|| this.parentModel.idtipoman === 8){
				
				return true;
			}
			return false;
		}
	},
	mounted() {
        this.$puiEvents.$on(`onPuiSelect_selectedItems-contacto-implicadocn`, newValContacto => {

            var lupamodel = newValContacto.model;

            if (!this.$appUtils.isEmpty(lupamodel)) {
				this.parentModel.implicadocn.implicadocto.nif = lupamodel.nif;
                this.parentModel.implicadocn.implicadocto.contacto = lupamodel.nombre;
				this.parentModel.implicadocn.implicadocto.implicadoctocmnc.numero = lupamodel.numero;				
            }

        });
    }
}
</script>
