<template>
	<div>
		<pui-datatable
			ref="puidocumentgrid"
			:modelName="modelName"
			:actions="actions"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"
			:externalFilter="documentFilter"
			:modelColumnDefs="modelColumnDefs"
			:showDeleteBtn="false"
		></pui-datatable>
		<puiDocumentNotificacion-modals :modelName="modelName"></puiDocumentNotificacion-modals>
	</div>
</template>

<script>
import PuiDocumentNotificacionActions from './PuiDocumentNotificacionActions';
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';
import PuiDocumentNotificacionModals from './PuiDocumentNotificacionModals';

export default {
	name: 'pui-document-notificacion-grid',
	mixins: [PuiGridDetailMixin],
	components: {
		'puiDocumentNotificacion-modals': PuiDocumentNotificacionModals
	},
	props: {
		parentModelName: {
			type: String
		},
		parentPk: {
			type: String
		},
		masterDetail: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			modelName: 'puidocument',
			actions: PuiDocumentNotificacionActions.actions,
			fileTypesDef: [
				{
					MimeType: 'image/png',
					icon: 'fas fa-file-image',
					extensions: ['png']
				},
				// {
				// 	MimeType: 'text/csv',
				// 	icon: 'fas fa-file-excel green--text',
				// 	extensions: ['csv']
				// },
				{
					MimeType: 'image/jpeg',
					icon: 'fas fa-file-image yellow--text',
					extensions: ['jpeg', 'jpg']
				},
				{
					MimeType: 'application/pdf',
					icon: 'fas fa-file-pdf red--text',
					extensions: ['pdf']
				},
				{
					MimeType: 'text/plain',
					icon: 'fas fa-file-alt blue--text',
					extensions: ['txt']
				},
				{
					MimeType: 'text/xml',
					icon: 'fas fa-file-alt',
					extensions: ['xml']
				}
				// {
				// 	MimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				// 	icon: 'fas fa-file-excel green--text',
				// 	extensions: ['xlsx']
				// }
			],

			modelColumnDefs: {
				id: {
					createdCell: (td, cellData, rowData) => {
						if (td.parentNode) {
							if (rowData.extraData && rowData.extraData.REVIEWED) {
								td.parentNode.classList.add('reviewed-row');
							}
						}
					},
					render: (id, e, rowData) => {
						const fileName = rowData.filenameorig || '';
						const selectedMymeTypeDefinition =
							this.fileTypesDef.find(def => def.extensions.some(extension => fileName.includes(extension))) || {};
						if (selectedMymeTypeDefinition.MimeType) {
							return `${id} <a href="showDocument_${id}_${selectedMymeTypeDefinition.MimeType}" target="_blank"> <i aria-hidden='true' class='v-icon ${selectedMymeTypeDefinition.icon} theme--light' style='font-size: 16px;'></i></a>`;
						} else {
							return `${id}`;
						}
					}
				}
			}
		};
	},
	methods: {},
	computed: {
		documentFilter() {
			const bpk = this.$puiUtils.b64ToUtf8(this.parentPk);
			const parentPkObject = JSON.parse(bpk);
			let pkValue = null;

			for (var key in parentPkObject) {
				const value = parentPkObject[key];
				pkValue = !pkValue ? value : pkValue + '#' + value;
			}

			const filter = { groupOp: 'and', rules: [], groups: [] };
			filter.rules.push({ field: 'model', op: 'eq', data: this.parentModelName });
			filter.rules.push({ field: 'pk', op: 'eq', data: pkValue });

			return filter;
		}
	}
};
</script>
<style lang="postcss">
.reviewed-row {
	color: rgb(4, 68, 2);

	background-color: rgba(193, 243, 184, 0.8) !important;

	//font-weight: bold;
}
</style>
