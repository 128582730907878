<template>
	<v-layout wrap class="pui-form-layout">
		<v-flex xs12 md12>
			<pui-field-set :title="$t('notificacion.implicados.contactoemergencia')">
				<v-layout wrap>
					<!-- IMPLICADO_CN -->
					<v-flex xs12 md3>
						<pui-text-field :label="$t('notificacion.implicado.nif')" v-model="model.nif" :disabled="true" toplabel></pui-text-field>
					</v-flex>
					<v-flex xs12 md9>
						<pui-text-field
							:label="$t('notificacion.implicado.nombre')"
							v-model="model.nombre"
							:disabled="true"
							toplabel
						></pui-text-field>
					</v-flex>
				</v-layout>
				<v-layout wrap>
					<!-- LUP-MCONTACTO -->
					<v-flex xs12 md12>
						<pui-select
							:id="'contacto-implicado-' + this.templatename"
							toplabel
							clearable
							:disabled="formDisabled"
							modelName="vlupmcontacto"
							:itemText="item => `${item.nombre} - ${item.nif} - ${item.numero}`"
							:order="{ nombre: 'asc' }"
							:fixedFilter="fixedFilterContacto"
						></pui-select>
					</v-flex>
				</v-layout>
				<v-layout wrap>
					<v-flex xs12 md3>
						<pui-text-field
							:label="$t('notificacion.implicados.nif')"
							v-model="model.implicadocto.nif"
							required
							toplabel
							:disabled="formDisabled"
						></pui-text-field>
					</v-flex>
					<v-flex xs12 md6>
						<pui-text-field
							:label="$t('notificacion.implicados.nombre')"
							v-model="model.implicadocto.contacto"
							required
							toplabel
							:disabled="formDisabled"
						></pui-text-field>
					</v-flex>
					<v-flex xs12 md3>
						<pui-text-field
							:label="$t('notificacion.implicados.telefono')"
							v-model="model.implicadocto.implicadoctocmnc.numero"
							implicadoctocmnc
							required
							toplabel
							:disabled="formDisabled"
						></pui-text-field>
					</v-flex>
				</v-layout>
			</pui-field-set>
		</v-flex>
	</v-layout>
</template>

<script>
export default {
	name: 'notificacion-form-implicado',
	data() {
		return {
			modelName: 'notificacion'
		};
	},
	props: {
		model: {
			type: Object,
			required: true
		},
		formDisabled: {
			type: Boolean,
			required: true
		},
		idpuertolocal: {
			type: Number,
			required: true
		},
		templatename: {
			type: String,
			required: true
		}
	},
	computed: {
		fixedFilterContacto() {
			return {
				groups: [
					{
						groupOp: 'and',
						rules: [{ field: 'idpuertolocal', op: 'eq', data: this.idpuertolocal }]
					}
				],
				groupOp: 'and',
				rules: []
			};
		}
	},
	mounted() {
		this.$puiEvents.$on(`onPuiSelect_selectedItems-contacto-implicado-${this.templatename}`, newValContacto => {
			var lupamodel = newValContacto.model;
			if (!this.$appUtils.isEmpty(lupamodel)) {
				this.model.implicadocto.nif = lupamodel.nif;
				this.model.implicadocto.contacto = lupamodel.nombre;
				this.model.implicadocto.implicadoctocmnc.numero = lupamodel.numero;
			}
		});
	}
};
</script>
