let selectedRegistry = null;

const downloadFileAction = {
	id: 'downloadFileAction',
	selectionType: 'single',
	label: 'puiaction.downloadfile',
	functionality: 'WRITE_PUI_DOCUMENT',
	checkAvailability: function() {
		return true;
	},
	runAction: function(action, model, registries) {
		const params = registries[0];
		selectedRegistry = params;
		const title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t(action.label);

		const url = model.url.download;
		this.$puiRequests.downloadFileRequest(
			'get',
			url,
			params,
			response => {
				const link = document.createElement('a');
				link.href = window.URL.createObjectURL(new window.Blob([response.data]));
				link.download = selectedRegistry.filenameorig;
				link.click();

				this.$puiEvents.$emit(`onPui-action-running-ended-${model.name}`);
				selectedRegistry = null;
			},
			() => {
				this.$puiEvents.$emit(`onPui-action-running-ended-${model.name}`);
				var message = this.$puiI18n.t('puiaction.notifyError');
				this.$puiNotify.error(message, title);
			}
		);
	}
};

const reviewDocumentAction = {
	id: 'reviewDocumentAction',
	selectionType: 'single',
	label: 'action.reviewDocument',
	functionality: 'REVIEW_DOCUMENT', //'REVIEW_DOCUMENT'
	checkAvailability: function(registries) {
		if (registries[0] && registries[0].extraData && registries[0].extraData.REVIEWED) {
			return false;
		}
		return true;
	},
	runAction: function(action, model, registries) {
		const params = registries[0];
		const url = `/notificaciondocumento/reviewDocument`;
		const title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t(action.label);

		const body = {
			model: params.model,
			pk: params.pk,
			language: params.language,
			description: params.description,
			filename: params.filename,
			filenameorig: params.filenameorig,
			role: params.role,
			thumbnails: params.thumbnails,
			datetime: params.datetime,
			id: params.id
		};

		return new Promise(resolve => {
			this.$puiRequests.patchRequest(
				url,
				body,
				() => {
					resolve(true);
					this.$puiNotify.success('', title);
					this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
				},
				() => {
					this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
					var message = this.$puiI18n.t('puiaction.notifyError');
					this.$puiNotify.error(message, title);
					resolve(false);
				}
			);
		});
	}
};

const deleteFileAction = {
	id: 'deleteFileAction',
	selectionType: 'single',
	label: 'action.deleteFile',
	functionality: 'WRITE_PUI_DOCUMENT',
	checkAvailability: function() {
		return true;
	},
	runAction: function(action, model, registries) {
		const row = registries[0];

		// Get PK for the header
		const objectPk = {};
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}

		row.headerPk = objectPk;
		row.isAction = true;

		this.$puiEvents.$emit('pui-modalDialogForm-deleteDocument-' + model.name + '-show', row);
	}
};

export default {
	actions: [downloadFileAction, reviewDocumentAction, deleteFileAction]
};
