<!-- eslint-disable no-unused-vars -->
<template>
	<div>
		<pui-modal-dialog-form
			:titleText="$t('pui9.delete.title')"
			:messageText="$t('pui9.delete.confirm')"
			:modelName="modelName"
			:dialogName="dialogNameDeleteDocument"
			:onOk="onOkDeleteNotificacion"
		>
			<template slot="message">
				<v-row dense>
					<!-- IDNOTIF -->
					<v-col cols="12">
						<pui-field :label="$t('pui9.delete.confirm')" value=""></pui-field>
					</v-col>
				</v-row>
			</template>
		</pui-modal-dialog-form>
	</div>
</template>

<script>
export default {
	name: 'pui-document-notificacion-modals',

	data() {
		return {
			dialogNameDeleteDocument: 'deleteDocument'
		};
	},
	props: {
		modelName: {
			required: true,
			type: String
		}
	},
	methods: {
		onOkDeleteNotificacion(modalData) {
			const title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t('action.deleteFile');
			const url = `/notificaciondocumento/deleteDocument`;

			return new Promise(resolve => {
				this.$puiRequests.deleteRequest(
					url,
					null,
					response => {
						if (response.status === 200) {
							this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
							var message = this.$puiI18n.t('puiaction.notifySuccess');
							this.$puiNotify.success(message, title);
							resolve(true);
						}
					},
					e => {
						// Error response, do code and not close modal
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiNotify.error(message, title);
						resolve(true);
					},
					{ idPuiDocument: modalData.id }
				);
			});
		}
	}
};
</script>
