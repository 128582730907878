<template>
	<v-layout wrap class="pui-form-layout">
		<v-flex xs12 md12>
			<v-layout wrap>
				<!-- IMPLICADO_CN -->
				<!-- IDAGENTEDEC -->
				<v-flex xs12 md12>
					<pui-select
						id="idagente"
						attach="idagente"
						:label="$t('notificacion.implicado.idagente')"
						toplabel
						clearable
						:disabled="formDisabled"
						v-model="model"
						modelName="vlupmagentetipopuerto"
						:itemsToSelect="[{ id: model.idagente }]"
						:modelFormMapping="{ id: 'idagente' }"
						itemValue="id"
						:itemText="item => `${item.nif} - ${item.nombre}`"
						:order="{ nombre: 'asc' }"
						:fixedFilter="fixedFilterEstibador"
					></pui-select>
				</v-flex>
			</v-layout>

			<pui-field-set :title="$t('notificacion.implicados.contactoemergencia')">
				<v-layout wrap>
					<!-- LUP-MCONTACTO -->
					<v-flex xs12 md12>
						<pui-select
							:id="'contacto-implicado-' + this.templatename"
							toplabel
							clearable
							:disabled="formDisabled"
							modelName="vlupmcontacto"
							:itemText="item => `${item.nombre} - ${item.nif} - ${item.numero}`"
							:order="{ nombre: 'asc' }"
							:fixedFilter="fixedFilterContacto"
						></pui-select>
					</v-flex>
				</v-layout>
				<v-layout wrap>
					<v-flex xs12 md3>
						<pui-text-field
							:label="$t('notificacion.implicados.nif')"
							v-model="model.implicadocto.nif"
							required
							toplabel
							:disabled="formDisabled"
						></pui-text-field>
					</v-flex>
					<v-flex xs12 md6>
						<pui-text-field
							:label="$t('notificacion.implicados.nombre')"
							v-model="model.implicadocto.contacto"
							required
							toplabel
							:disabled="formDisabled"
						></pui-text-field>
					</v-flex>
					<v-flex xs12 md3>
						<pui-text-field
							:label="$t('notificacion.implicados.telefono')"
							v-model="model.implicadocto.implicadoctocmnc.numero"
							implicadoctocmnc
							required
							toplabel
							:disabled="formDisabled"
						></pui-text-field>
					</v-flex>
				</v-layout>
			</pui-field-set>
			<pui-field-set :title="$t('notificacion.implicados.operadorterminal')">
				<v-layout wrap>
					<!-- MAGENTE -->
					<v-flex xs12 md12>
						<pui-select
							v-if="operadorMuelleAgente == 1"
							:id="`agente-implicado-${this.templatename}`"
							toplabel
							clearable
							:disabled="formDisabled"
							modelName="vlupmagentetipo"
							:itemText="
								item =>
									`${item.nombre} - ${item.email === undefined ? '' : item.email} - ${item.numero === undefined ? '' : item.numero}`
							"
							:order="{ nombre: 'asc' }"
							v-model="model.implicadoctowo"
							:itemsToSelect="[{ idagente: model.implicadoctowo.idagente }]"
							:modelFormMapping="{ idagente: 'idagente' }"
							itemValue="idagente"
							:fixedFilter="fixedFilterAgenteOperador"
						></pui-select>
					</v-flex>
					<!-- LUP-MCONTACTO -->
					<v-flex xs12 md12>
						<pui-select
							v-if="operadorMuelleAgente == 0"
							:id="`contactowo-implicado-${this.templatename}`"
							toplabel
							clearable
							:disabled="formDisabled"
							modelName="vlupmcontacto"
							:itemText="item => `${item.nombre} - ${item.nif} - ${item.numero}`"
							:order="{ nombre: 'asc' }"
							:fixedFilter="fixedFilterContacto"
						></pui-select>
					</v-flex>
				</v-layout>
				<v-layout wrap>
					<v-flex xs12 md3>
						<pui-text-field
							:label="$t('notificacion.implicados.nif')"
							v-model="model.implicadoctowo.nif"
							required
							toplabel
							:disabled="formDisabled"
						></pui-text-field>
					</v-flex>
					<v-flex xs12 md6>
						<pui-text-field
							:label="$t('notificacion.implicados.nombre')"
							v-model="model.implicadoctowo.contacto"
							required
							toplabel
							:disabled="formDisabled"
						></pui-text-field>
					</v-flex>
					<v-flex xs12 md3>
						<pui-text-field
							:label="$t('notificacion.implicados.telefono')"
							v-model="model.implicadoctowo.implicadoctocmnc.numero"
							required
							toplabel
							:disabled="formDisabled"
						></pui-text-field>
					</v-flex>
				</v-layout>
			</pui-field-set>
		</v-flex>
	</v-layout>
</template>

<script>
export default {
	name: 'notificaciofixedFilterEstibadorn-form-implicado-estibador',
	data() {
		return {
			modelName: 'notificacion',
			operadorMuelleAgente: null,
			firstTime: false
		};
	},
	props: {
		model: {
			type: Object,
			required: true
		},
		formDisabled: {
			type: Boolean,
			required: true
		},
		idpuertolocal: {
			type: Number,
			required: true
		},
		templatename: {
			type: String,
			required: true
		}
	},
	methods: {
		getOperadorMuelleAgente() {
			const puiVariableUrl = '/puivariable/getVariable/OPERADOR_MUELLE_AGENTE';
			this.$puiRequests.getRequest(
				puiVariableUrl,
				null,
				response => {
					this.operadorMuelleAgente = response.data;
				},
				error => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
				}
			);
		}
	},
	computed: {
		fixedFilterContacto() {
			return {
				groups: [
					{
						groupOp: 'and',
						rules: [{ field: 'idpuertolocal', op: 'eq', data: this.idpuertolocal }]
					}
				],
				groupOp: 'and',
				rules: []
			};
		},
		fixedFilterEstibador() {
			return {
				groups: [
					{
						groupOp: 'and',
						rules: [
							{ field: 'puertolocal', op: 'eq', data: this.idpuertolocal },
							{ field: 'tipoagente', op: 'eq', data: 1 }
						]
					}
				],
				groupOp: 'and',
				rules: []
			};
		},
		fixedFilterAgenteOperador() {
			return {
				groups: [
					{
						groupOp: 'and',
						rules: [
							{ field: 'puertolocal', op: 'eq', data: this.idpuertolocal },
							{ field: 'tipoagente', op: 'eq', data: 9 }
						]
					}
				],
				groupOp: 'and',
				rules: []
			};
		}
	},
	mounted() {
		this.getOperadorMuelleAgente();
		this.$puiEvents.$on(`onPuiSelect_selectedItems-contacto-implicado-${this.templatename}`, newValContacto => {
			var lupamodel = newValContacto.model;
			if (!this.$appUtils.isEmpty(lupamodel)) {
				this.model.implicadocto.nif = lupamodel.nif;
				this.model.implicadocto.contacto = lupamodel.nombre;
				this.model.implicadocto.implicadoctocmnc.numero = lupamodel.numero;
			}
		});
		this.$puiEvents.$on(`onPuiSelect_selectedItems-contactowo-implicado-${this.templatename}`, newValContacto => {
			var lupamodel = newValContacto.model;
			if (!this.$appUtils.isEmpty(lupamodel)) {
				this.model.implicadoctowo.nif = lupamodel.nif;
				this.model.implicadoctowo.contacto = lupamodel.nombre;
				this.model.implicadoctowo.implicadoctocmnc.numero = lupamodel.numero;
			}
		});
		this.$puiEvents.$on(`onPuiSelect_selectedItems-agente-implicado-${this.templatename}`, newValContacto => {
			var lupamodel = newValContacto.model;
			if (!this.$appUtils.isEmpty(lupamodel) && this.firstTime) {
				this.model.implicadoctowo.nif = lupamodel.nif;
				this.model.implicadoctowo.contacto = lupamodel.nombre;
				this.model.implicadoctowo.implicadoctocmnc.numero = lupamodel.numero;
			}
			this.firstTime = true;
		});

		if (this.model.implicadoctowo.idagente === null) {
			this.firstTime = true;
		}
	},
	destroyed() {
		this.$puiEvents.$off(`onPuiSelect_selectedItems-contacto-implicado-${this.templatename}`);
		this.$puiEvents.$off(`onPuiSelect_selectedItems-contactowo-implicado-${this.templatename}`);
		this.$puiEvents.$off(`onPuiSelect_selectedItems-agente-implicado-${this.templatename}`);
	}
};
</script>
