<template>
	<div class="pui-form">
		<notificacion-modals :modelName="modelName"></notificacion-modals>
		<pui-form-header v-if="modelLoaded" :showHeader="!isCreatingElement">
			<notificacion-form-header :modelPk="modelPk"></notificacion-form-header>
		</pui-form-header>

		<v-tabs v-model="tabmodel" class="ml-3 mb-3" slider-color="primary" show-arrows>
			<v-tab :key="0" :href="'#maintab'">{{ $t('notificacion.maintab') }}</v-tab>
			<v-tab :key="1" :href="'#implicados'" v-if="!isCreatingElement && !isModalDialog">{{ $t('notificacion.implicadostab') }}</v-tab>
			<v-tab :key="2" :href="'#equipamientos'" v-if="!isCreatingElement && !isModalDialog">{{ $t('notificacion.equipamientostab') }}</v-tab>
			<v-tab :key="3" :href="'#partidas'" v-if="!isCreatingElement && !isModalDialog">{{ $t('notificacion.partidastab') }}</v-tab>
			<v-tab :key="4" :href="'#documentos'" v-if="!isCreatingElement && !isModalDialog">{{ $t('pui9.documents.tab') }}</v-tab>

			<!--<v-tab :key="1" :href="'#equipamientos'" v-if="!isCreatingElement">{{ $t('notificacion.equipamientostab') }}</v-tab>
			<v-tab :key="3" :href="'#partidas'" v-if="!isCreatingElement">{{ $t('notificacion.partidastab') }}</v-tab>
			<v-tab :key="4" :href="'#documentos'" v-if="!isCreatingElement && showDocuments">{{ $t('pui9.documents.tab') }}</v-tab>-->
		</v-tabs>

		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-tabs-items v-model="tabmodel">
				<v-tab-item :key="0" lazy :value="'maintab'">
					<v-layout wrap class="pui-form-layout">
						<v-flex xs12 md12>
							<pui-field-set :title="getTitleDatos">
								<v-layout wrap>
									<!-- IDNOTIF -->
									<v-flex xs12 md1>
										<pui-text-field
											:id="`idnotif-notificacion`"
											v-model="model.idnotif"
											:label="$t('notificacion.idnotif')"
											disabled
											toplabel
											maxlength="10"
										></pui-text-field>
									</v-flex>
									<!-- NUMDOC -->
									<v-flex xs12 md5>
										<pui-text-field
											:id="`numdoc-notificacion`"
											v-model="model.numdoc"
											:label="$t('notificacion.numdoc')"
											disabled
											toplabel
											maxlength="35"
										></pui-text-field>
									</v-flex>
									<!-- IDESTADO -->
									<v-flex xs12 md3>
										<pui-select
											id="idestado"
											attach="idestado"
											:label="$t('notificacion.idestado')"
											toplabel
											clearable
											required
											disabled
											v-model="model"
											modelName="vlupnotifestado"
											:itemsToSelect="[{ codigo: model.idestado }]"
											:modelFormMapping="{ codigo: 'idestado' }"
											itemValue="codigo"
											itemText="descripcion"
											:order="{ descripcion: 'asc' }"
										></pui-select>
									</v-flex>
									<!-- ART15 -->
									<v-flex xs12 md1 align-self-center>
										<pui-checkbox
											:label="$t('notificacion.art15')"
											v-model="model.art15"
											true-value="S"
											false-value="N"
											disabled
										></pui-checkbox>
									</v-flex>
									<!-- MANUAL -->
									<v-flex xs12 md2 align-self-center>
										<pui-checkbox
											:label="$t('notificacion.manual')"
											v-model="model.manual"
											true-value="S"
											false-value="N"
											disabled
										></pui-checkbox>
									</v-flex>
								</v-layout>
								<v-layout wrap>
									<!-- IDNOMDOCUMENTO -->
									<v-flex xs12 md4>
										<pui-select
											id="idnomdocumento"
											attach="idnomdocumento"
											:label="$t('notificacion.idnomdocumento')"
											toplabel
											clearable
											required
											v-model="model"
											:disabled="formDisabled"
											modelName="vlupmnomdocumento"
											:itemsToSelect="[{ id: model.idnomdocumento }]"
											:modelFormMapping="{ id: 'idnomdocumento' }"
											itemValue="id"
											:itemText="item => `${item.codigo} - ${item.name}`"
											:order="{ name: 'asc' }"
										></pui-select>
									</v-flex>
									<!-- IDNOTIFORIGEN
									<v-flex xs12 md4 v-if="model.idnotiforigen!=null">
										<pui-select
											id="idnotiforigen"
											attach="idnotiforigen"
											:label="$t('notificacion.idnotiforigen')"
											toplabel
											clearable
											disabled
											v-model="model"
											modelName="vlupnotificacion"
											:itemsToSelect="[{ id: model.idnotiforigen }]"
											:modelFormMapping="{ id: 'idnotiforigen' }"
											itemValue="id"
											:itemText="item => `${item.numdoc}`"
											:order="{ name: 'asc' }"
										></pui-select>
									</v-flex>-->
								</v-layout>
								<v-layout wrap>
									<!-- FECNOTPRESENTACION -->
									<v-flex xs12 md3>
										<pui-date-field
											:id="`fecnotpresentacion-notificacion`"
											:label="$t('notificacion.fecnotpresentacion')"
											v-model="model.fecnotpresentacion"
											:disabled="formDisabled"
											required
											toplabel
											time
										></pui-date-field>
									</v-flex>
									<!-- IDPUERTOLOCAL -->
									<v-flex xs12 md3>
										<pui-select
											id="idpuertolocal"
											attach="idpuertolocal"
											:label="$t('notificacion.idpuertolocal')"
											toplabel
											clearable
											required
											:disabled="formDisabled"
											v-model="model"
											modelName="vlupmpuertolocal"
											:itemsToSelect="[{ id: model.idpuertolocal }]"
											:modelFormMapping="{ id: 'idpuertolocal' }"
											itemValue="id"
											itemText="descripcion"
											:order="{ descripcion: 'asc' }"
										></pui-select>
									</v-flex>

									<!-- IDAGENTEDEC -->
									<v-flex xs12 md6>
										<pui-select
											id="idagentedec"
											attach="idagentedec"
											:label="$t('notificacion.idagentedec')"
											toplabel
											clearable
											required
											:disabled="formDisabled"
											v-model="model"
											modelName="vlupmagentetipopuerto"
											:itemsToSelect="[{ id: model.idagentedec }]"
											:modelFormMapping="{ id: 'idagentedec' }"
											itemValue="id"
											:itemText="item => `${item.nif} - ${item.nombre}`"
											:order="{ nombre: 'asc' }"
											:fixedFilter="fixedFilterAgenteConsignatario"
										></pui-select>
									</v-flex>
								</v-layout>
								<v-layout wrap>
									<!-- IDTIPOMAN -->
									<v-flex xs12 md6>
										<pui-select
											id="idtipoman"
											attach="idtipoman"
											:label="$t('notificacion.idtipoman')"
											toplabel
											clearable
											required
											:disabled="formDisabled"
											v-model="model"
											modelName="vlupmtipomanipulacion"
											:itemsToSelect="[{ id: model.idtipoman }]"
											:modelFormMapping="{ id: 'idtipoman' }"
											itemValue="id"
											itemText="name"
											:order="{ name: 'asc' }"
										></pui-select>
									</v-flex>
								</v-layout>

								<!---------------------------------------------------------------------------------------------------------------------------------------->
								<!---------------------------------------------------------- layout ENTRADA -------------------------------------------------------------->
								<!---------------------------------------------------------------------------------------------------------------------------------------->

								<v-layout wrap>
									<v-flex xs12 md6>
										<pui-field-set title="ENTRADA">
											<v-layout wrap>
												<!-- IDTIPOTRANSENT -->
												<v-flex xs12 md8>
													<pui-select
														id="idtipotransent"
														attach="idtipotransent"
														:label="$t('notificacion.idtipotransent')"
														toplabel
														clearable
														required
														:disabled="formDisabled"
														v-model="model"
														modelName="vlupmtipotransporte"
														:itemsToSelect="[{ id: model.idtipotransent }]"
														:modelFormMapping="{ id: 'idtipotransent' }"
														itemValue="id"
														:itemText="item => `${item.codedi} - ${item.name}`"
														:order="{ codedi: 'asc' }"
														:fixedFilter="fixedFilterTransporteEntrada"
													></pui-select>
												</v-flex>
											</v-layout>
											<v-layout wrap v-if="visibleLayoutEntradaMaritima">
												<v-flex xs12 md12>
													<pui-field-set title="Marítima">
														<v-layout wrap>
															<!-- wsescala -->
															<v-flex xs12 md12 v-if="!formDisabled">
																<pui-select
																	id="wsescalaent"
																	attach="wsescalaent"
																	:label="$t('notificacion.lupaescala')"
																	toplabel
																	clearable
																	required
																	:disabled="formDisabled"
																	v-model="model"
																	modelName="vlupescalafake"
																	:itemsToSelect="[{ numescala: model.numescent }]"
																	:modelFormMapping="{ numescala: 'numescent' }"
																	itemValue="numescala"
																	:itemText="
																		item =>
																			`${item.numescala} - ${item.nombuque} - ${item.imobuque} - ${item.codpaisbandera} - ${item.bandera} - ${item.nomcv}`
																	"
																	:order="{ numescala: 'asc' }"
																	:fixedFilter="fixedFilterwsescalaent"
																></pui-select>
															</v-flex>
															<v-flex xs12 md12 v-if="formDisabled">
																<pui-text-field
																	:id="`matvehent-notificacion`"
																	:value="getEscalaEntradaTextValue"
																	:label="$t('notificacion.lupaescala')"
																	:disabled="formDisabled"
																	toplabel
																	maxlength="9"
																></pui-text-field>
															</v-flex>
														</v-layout>
														<v-layout wrap>
															<!-- IDPUEANTESCENT -->
															<v-flex xs12 md6>
																<pui-select
																	id="idpueantescent"
																	attach="idpueantescent"
																	:label="$t('notificacion.idpueantescent')"
																	toplabel
																	reactive
																	disabled
																	clearable
																	v-model="model"
																	modelName="vlupmlocalidad"
																	:itemsToSelect="[{ id: model.idpueantescent }]"
																	:modelFormMapping="{ id: 'idpueantescent' }"
																	itemValue="id"
																	:itemText="item => `${item.name} - ${item.codlocodepais}${item.codlocode}`"
																	:order="{ name: 'asc' }"
																></pui-select>
															</v-flex>
															<!-- IDPUESIGESCENT -->
															<v-flex xs12 md6>
																<pui-select
																	id="idpuesigescent"
																	attach="idpuesigescent"
																	:label="$t('notificacion.idpuesigescent')"
																	toplabel
																	reactive
																	clearable
																	disabled
																	v-model="model"
																	modelName="vlupmlocalidad"
																	:itemsToSelect="[{ id: model.idpuesigescent }]"
																	:modelFormMapping="{ id: 'idpuesigescent' }"
																	itemValue="id"
																	:itemText="item => `${item.name} - ${item.codlocodepais}${item.codlocode}`"
																	:order="{ name: 'asc' }"
																></pui-select>
															</v-flex>
														</v-layout>
														<!-- DOBLE CASCO -->
														<v-layout wrap>
															<v-flex xs12 md6 align-self-center>
																<pui-checkbox
																	:title="$t('notificacion.doblecascoent.title')"
																	:label="$t('notificacion.doblecascoent')"
																	v-model="model.doblecascoent"
																	true-value="S"
																	false-value="N"
																	disabled
																></pui-checkbox>
															</v-flex>
														</v-layout>
														<v-layout wrap>
															<v-flex xs12 md6 align-self-center><span>&nbsp;</span></v-flex>
														</v-layout>
														<v-layout wrap>
															<!-- AVERIACASCOENT -->
															<v-flex xs12 md6 align-self-center>
																<pui-checkbox
																	:title="$t('notificacion.averiacasco.title')"
																	:label="$t('notificacion.averiacascoent')"
																	v-model="model.averiacascoent"
																	true-value="S"
																	false-value="N"
																	:disabled="formDisabled"
																></pui-checkbox>
															</v-flex>

															<!-- CERSOLASENT -->
															<v-flex xs12 md6 align-self-center>
																<pui-checkbox
																	:title="$t('notificacion.cersolas.title')"
																	:label="$t('notificacion.cersolasent')"
																	v-model="model.cersolasent"
																	true-value="S"
																	false-value="N"
																	:disabled="formDisabled"
																></pui-checkbox>
															</v-flex>
														</v-layout>
														<v-layout wrap>
															<!-- BUQCERTOMIENT -->
															<v-flex xs12 md6 align-self-center>
																<pui-checkbox
																	:title="$t('notificacion.buqcertomi.title')"
																	:label="$t('notificacion.buqcertomient')"
																	v-model="model.buqcertomient"
																	true-value="S"
																	false-value="N"
																	:disabled="formDisabled"
																></pui-checkbox>
															</v-flex>
															<!-- DECMMPPDOCENT -->
															<v-flex xs12 md6 align-self-center>
																<pui-checkbox
																	:title="$t('notificacion.decmmppdoc.title')"
																	:label="$t('notificacion.decmmppdocent')"
																	v-model="model.decmmppdocent"
																	true-value="S"
																	false-value="N"
																	:disabled="formDisabled"
																></pui-checkbox>
															</v-flex>
														</v-layout>
													</pui-field-set>
												</v-flex>
											</v-layout>
											<v-layout wrap v-if="visibleLayoutEntradaTerrestre">
												<v-flex xs12 md12>
													<pui-field-set title="Terrestre">
														<v-layout wrap>
															<!-- NUMVEHENT -->
															<v-flex xs12 md6>
																<pui-number-field
																	:id="`numvehent-notificacion`"
																	v-model="model.numvehent"
																	:label="$t('notificacion.numvehent')"
																	:disabled="formDisabled"
																	:required="requiredVehiculosNombreEntrada"
																	toplabel
																	integer
																	min="0"
																	max="9999999999999999"
																></pui-number-field>
															</v-flex>
															<!-- MATVEHENT -->
															<v-flex xs12 md6>
																<pui-text-field
																	:id="`matvehent-notificacion`"
																	v-model="model.matvehent"
																	:label="$t('notificacion.matvehent')"
																	:disabled="formDisabled"
																	toplabel
																	maxlength="9"
																></pui-text-field>
															</v-flex>
														</v-layout>
														<v-layout wrap>
															<!-- NOMTRANSENT -->
															<v-flex xs12 md12>
																<pui-text-field
																	:id="`nomtransent-notificacion`"
																	v-model="model.nomtransent"
																	:label="$t('notificacion.nomtransent')"
																	:disabled="formDisabled"
																	:required="requiredVehiculosNombreEntrada"
																	toplabel
																	maxlength="35"
																></pui-text-field>
															</v-flex>
														</v-layout>
														<v-layout wrap>
															<!-- NORADRENT -->
															<v-flex xs12 md6 align-self-center>
																<pui-checkbox
																	:title="$t('notificacion.noradrent.title')"
																	:label="$t('notificacion.noradrent')"
																	v-model="model.noradrent"
																	true-value="S"
																	false-value="N"
																	:disabled="formDisabled"
																></pui-checkbox>
															</v-flex>
															<!-- CONADRENT -->
															<v-flex xs12 md6 align-self-center>
																<pui-checkbox
																	:title="$t('notificacion.conadrent.title')"
																	:label="$t('notificacion.conadrent')"
																	v-model="model.conadrent"
																	true-value="S"
																	false-value="N"
																	:disabled="formDisabled"
																></pui-checkbox>
															</v-flex>
														</v-layout>
														<v-layout wrap>
															<!-- PLANPROTECADRENT -->
															<v-flex xs12 md6 align-self-center>
																<pui-checkbox
																	:title="$t('notificacion.planprotecadrent.title')"
																	:label="$t('notificacion.planprotecadrent')"
																	v-model="model.planprotecadrent"
																	true-value="S"
																	false-value="N"
																	:disabled="formDisabled"
																></pui-checkbox>
															</v-flex>
														</v-layout>
													</pui-field-set>
												</v-flex>
											</v-layout>
											<v-layout wrap v-if="visibleLayoutNomtransentInstfijas">
												<!-- NOMTRANSENT (instalacionesfijas - nombre instalación)-->
												<v-flex xs12 md12>
													<pui-text-field
														:id="`nomtransent2-notificacion`"
														v-model="model.nomtransent"
														:label="$t('notificacion.nomtransent')"
														:disabled="formDisabled"
														required
														toplabel
														maxlength="35"
													></pui-text-field>
												</v-flex>
											</v-layout>
											<v-layout wrap>
												<!-- FECENTMER -->
												<v-flex xs12 md6>
													<pui-date-field
														:id="`fecentmer-notificacion`"
														:label="$t('notificacion.fecentmer')"
														v-model="model.fecentmer"
														:max="model.fecmer"
														:disabled="formDisabled"
														toplabel
														required
														time
													></pui-date-field>
												</v-flex>
												<!-- PERIODODESCARGA -->
												<v-flex xs12 md3>
													<pui-number-field
														:id="`periododescarga-notificacion`"
														v-model="model.periododescarga"
														:label="$t('notificacion.periododescarga')"
														:disabled="formDisabled"
														toplabel
														integer
														min="0"
														max="9999999999999999999999"
														required
													></pui-number-field>
												</v-flex>
											</v-layout>
										</pui-field-set>
									</v-flex>

									<!---------------------------------------------------------------------------------------------------------------------------------------->
									<!---------------------------------------------------------- FIN layout ENTRADA ---------------------------------------------------------->
									<!---------------------------------------------------------------------------------------------------------------------------------------->

									<!---------------------------------------------------------------------------------------------------------------------------------------->
									<!---------------------------------------------------------- Layout SALIDA --------------------------------------------------------------->
									<!---------------------------------------------------------------------------------------------------------------------------------------->
									<v-flex xs12 md6>
										<pui-field-set title="SALIDA" v-if="visibleLayoutSalida">
											<v-layout wrap>
												<!-- IDTIPOTRANSSAL -->
												<v-flex xs12 md8>
													<pui-select
														id="idtipotranssal"
														attach="idtipotranssal"
														:label="$t('notificacion.idtipotranssal')"
														toplabel
														clearable
														required
														:disabled="formDisabled"
														v-model="model"
														modelName="vlupmtipotransporte"
														:itemsToSelect="[{ id: model.idtipotranssal }]"
														:modelFormMapping="{ id: 'idtipotranssal' }"
														itemValue="id"
														:itemText="item => `${item.codedi} - ${item.name}`"
														:order="{ codedi: 'asc' }"
														:fixedFilter="fixedFilterTransporteSalida"
													></pui-select>
												</v-flex>
											</v-layout>
											<v-layout wrap v-if="visibleLayoutSalidaMaritima">
												<v-flex xs12 md12>
													<pui-field-set title="Marítima">
														<v-layout wrap v-if="!formDisabled">
															<!-- wsescala -->
															<v-flex xs12 md12>
																<pui-select
																	id="wsescalasal"
																	attach="wsescalasal"
																	:label="$t('notificacion.lupaescala')"
																	toplabel
																	clearable
																	required
																	:disabled="formDisabled"
																	v-model="model"
																	modelName="vlupescalafake"
																	:itemsToSelect="[{ numescala: model.numescsal }]"
																	:modelFormMapping="{ numescala: 'numescsal' }"
																	itemValue="numescala"
																	:itemText="
																		item =>
																			`${item.numescala} - ${item.nombuque} - ${item.imobuque} - ${item.codpaisbandera} - ${item.bandera} - ${item.nomcv}`
																	"
																	:order="{ numescala: 'asc' }"
																	:fixedFilter="fixedFilterwsescalasal"
																></pui-select>
															</v-flex>
														</v-layout>
														<v-layout wrap v-if="formDisabled">
															<!-- escalaTEXT -->
															<v-flex xs12 md12>
																<pui-text-field
																	:label="$t('notificacion.lupaescala')"
																	:value="getEscalaSalidaTextValue"
																	toplabel
																	disabled
																></pui-text-field>
															</v-flex>
														</v-layout>
														<v-layout wrap>
															<!-- IDPUEANTESCSAL -->
															<v-flex xs12 md6>
																<pui-select
																	id="idpueantescsal"
																	attach="idpueantescsal"
																	:label="$t('notificacion.idpueantescsal')"
																	toplabel
																	clearable
																	disabled
																	reactive
																	v-model="model"
																	modelName="vlupmlocalidad"
																	:itemsToSelect="[{ id: model.idpueantescsal }]"
																	:modelFormMapping="{ id: 'idpueantescsal' }"
																	itemValue="id"
																	:itemText="item => `${item.name} - ${item.codlocodepais}${item.codlocode}`"
																	:order="{ name: 'asc' }"
																></pui-select>
															</v-flex>
															<!-- IDPUESIGESCSAL -->
															<v-flex xs12 md6>
																<pui-select
																	id="idpuesigescsal"
																	attach="idpuesigescsal"
																	:label="$t('notificacion.idpuesigescsal')"
																	toplabel
																	clearable
																	disabled
																	reactive
																	v-model="model"
																	modelName="vlupmlocalidad"
																	:itemsToSelect="[{ id: model.idpuesigescsal }]"
																	:modelFormMapping="{ id: 'idpuesigescsal' }"
																	itemValue="id"
																	:itemText="item => `${item.name} - ${item.codlocodepais}${item.codlocode}`"
																	:order="{ name: 'asc' }"
																></pui-select>
															</v-flex>
														</v-layout>
														<!-- DOBLE CASCO -->
														<v-layout wrap>
															<v-flex xs12 md6 align-self-center>
																<pui-checkbox
																	:title="$t('notificacion.doblecascosal.title')"
																	:label="$t('notificacion.doblecascosal')"
																	v-model="model.doblecascosal"
																	true-value="S"
																	false-value="N"
																	disabled
																></pui-checkbox>
															</v-flex>
														</v-layout>
														<v-layout wrap>
															<v-flex xs12 md6 align-self-center><span>&nbsp;</span></v-flex>
														</v-layout>
														<v-layout wrap>
															<!-- AVERIACASCOSAL -->
															<v-flex xs12 md6 align-self-center>
																<pui-checkbox
																	:title="$t('notificacion.averiacasco.title')"
																	:label="$t('notificacion.averiacascosal')"
																	v-model="model.averiacascosal"
																	true-value="S"
																	false-value="N"
																	:disabled="formDisabled"
																></pui-checkbox>
															</v-flex>
															<!-- CERSOLASSAL -->
															<v-flex xs12 md6 align-self-center>
																<pui-checkbox
																	:title="$t('notificacion.cersolas.title')"
																	:label="$t('notificacion.cersolassal')"
																	v-model="model.cersolassal"
																	true-value="S"
																	false-value="N"
																	:disabled="formDisabled"
																></pui-checkbox>
															</v-flex>
														</v-layout>
														<v-layout wrap>
															<!-- BUQCERTOMISAL -->
															<v-flex xs12 md6 align-self-center>
																<pui-checkbox
																	:title="$t('notificacion.buqcertomi.title')"
																	:label="$t('notificacion.buqcertomisal')"
																	v-model="model.buqcertomisal"
																	true-value="S"
																	false-value="N"
																	:disabled="formDisabled"
																></pui-checkbox>
															</v-flex>
															<!-- DECMMPPDOCSAL -->
															<v-flex xs12 md6 align-self-center>
																<pui-checkbox
																	:title="$t('notificacion.decmmppdoc.title')"
																	:label="$t('notificacion.decmmppdocsal')"
																	v-model="model.decmmppdocsal"
																	true-value="S"
																	false-value="N"
																	:disabled="formDisabled"
																></pui-checkbox>
															</v-flex>
														</v-layout>
													</pui-field-set>
												</v-flex>
											</v-layout>
											<v-layout wrap v-if="visibleLayoutSalidaTerrestre">
												<v-flex xs12 md12>
													<pui-field-set title="Terrestre">
														<v-layout wrap>
															<!-- NUMVEHSAL -->
															<v-flex xs12 md6>
																<pui-number-field
																	:id="`numvehsal-notificacion`"
																	v-model="model.numvehsal"
																	:label="$t('notificacion.numvehsal')"
																	:disabled="formDisabled"
																	:required="requiredVehiculosNombreSalida"
																	toplabel
																	integer
																	min="0"
																	max="99999999999999999"
																></pui-number-field>
															</v-flex>
															<!-- MATVEHSAL -->
															<v-flex xs12 md6>
																<pui-text-field
																	:id="`matvehsal-notificacion`"
																	v-model="model.matvehsal"
																	:label="$t('notificacion.matvehsal')"
																	:disabled="formDisabled"
																	toplabel
																	maxlength="9"
																></pui-text-field>
															</v-flex>
														</v-layout>
														<v-layout wrap>
															<!-- NOMTRANSSAL -->
															<v-flex xs12 md12>
																<pui-text-field
																	:id="`nomtranssal-notificacion`"
																	v-model="model.nomtranssal"
																	:label="$t('notificacion.nomtranssal')"
																	:disabled="formDisabled"
																	:required="requiredVehiculosNombreSalida"
																	toplabel
																	maxlength="35"
																></pui-text-field>
															</v-flex>
														</v-layout>
														<v-layout wrap>
															<!-- NORADRSAL -->
															<v-flex xs12 md6 align-self-center>
																<pui-checkbox
																	:title="$t('notificacion.noradrsal.title')"
																	:label="$t('notificacion.noradrsal')"
																	v-model="model.noradrsal"
																	true-value="S"
																	false-value="N"
																	:disabled="formDisabled"
																></pui-checkbox>
															</v-flex>
															<!-- CONADRENT -->
															<v-flex xs12 md6 align-self-center>
																<pui-checkbox
																	:title="$t('notificacion.conadrsal.title')"
																	:label="$t('notificacion.conadrsal')"
																	v-model="model.conadrsal"
																	true-value="S"
																	false-value="N"
																	:disabled="formDisabled"
																></pui-checkbox>
															</v-flex>
														</v-layout>
														<v-layout wrap>
															<!-- PLANPROTECADRENT -->
															<v-flex xs12 md6 align-self-center>
																<pui-checkbox
																	:title="$t('notificacion.planprotecadrsal.title')"
																	:label="$t('notificacion.planprotecadrsal')"
																	v-model="model.planprotecadrsal"
																	true-value="S"
																	false-value="N"
																	:disabled="formDisabled"
																></pui-checkbox>
															</v-flex>
														</v-layout>
													</pui-field-set>
												</v-flex>
											</v-layout>
											<v-layout wrap v-if="visibleLayoutNomtranssalInstfijas">
												<!-- NOMTRANSSAL (instalacionesfijas - nombre instalación)-->
												<v-flex xs12 md12>
													<pui-text-field
														:id="`nomtranssal2-notificacion`"
														v-model="model.nomtranssal"
														:label="$t('notificacion.nomtranssal')"
														:disabled="formDisabled"
														required
														toplabel
														maxlength="35"
													></pui-text-field>
												</v-flex>
											</v-layout>
											<v-layout wrap>
												<!-- FECSALMER -->
												<v-flex xs12 md6>
													<pui-date-field
														:id="`fecsalmer-notificacion`"
														:label="$t('notificacion.fecsalmer')"
														v-model="model.fecsalmer"
														:min="model.fecentmer"
														:disabled="formDisabled"
														toplabel
														time
														required
													></pui-date-field>
												</v-flex>
												<!-- PERIODOCARGA -->
												<v-flex xs12 md3>
													<pui-number-field
														:id="`periodocarga-notificacion`"
														v-model="model.periodocarga"
														:label="$t('notificacion.periodocarga')"
														:disabled="formDisabled"
														toplabel
														integer
														min="0"
														max="9999999999999999999999"
														required
													></pui-number-field>
												</v-flex>
											</v-layout>
										</pui-field-set>
									</v-flex>
								</v-layout>

								<!---------------------------------------------------------------------------------------------------------------------------------------->
								<!---------------------------------------------------------- FIN layout SALIDA ----------------------------------------------------------->
								<!---------------------------------------------------------------------------------------------------------------------------------------->

								<v-layout wrap>
									<!-- IDAREAOPERA -->
									<v-flex xs12 md5>
										<pui-select
											id="notificacion-idareaopera"
											attach="idareaopera"
											:label="$t('notificacion.idareaopera')"
											toplabel
											clearable
											:disabled="formDisabled"
											v-model="model"
											modelName="vlupmareaoperacion"
											:itemsToSelect="[{ idareaopera: model.idareaopera }]"
											:modelFormMapping="{ idareaopera: 'idareaopera' }"
											itemValue="idareaopera"
											:itemText="item => `${item.name} - ${item.codareaoperaedi}`"
											:order="{ name: 'asc' }"
											:fixedFilter="fixedFilterAreaopera"
										></pui-select>
									</v-flex>
									<v-flex xs12 md5>
										<pui-select
											v-if="this.mostrarTerminal === 1"
											id="mterminal-idterminal"
											attach="idterminal"
											:label="$t('notificacion.terminal')"
											toplabel
											clearable
											:disabled="formDisabled"
											v-model="model"
											modelName="mterminal"
											:itemsToSelect="[{ idterminal: model.idterminal }]"
											:modelFormMapping="{ idterminal: 'idterminal' }"
											itemValue="idterminal"
											:itemText="item => `${item.codterminal} - ${item.name}`"
											:order="{ name: 'asc' }"
											:fixedFilter="fixedFilterTerminal"
										></pui-select>
									</v-flex>
								</v-layout>

								<v-layout wrap>
									<!-- DECLARACION -->
									<v-flex xs12 md12>
										<pui-text-area
											:id="`declaracion-notificacion`"
											v-model="model.declaracion"
											:label="$t('notificacion.declaracion')"
											:disabled="formDisabled"
											maxlength="350"
											toplabel
										></pui-text-area>
									</v-flex>
								</v-layout>

								<v-layout wrap>
									<!-- razoreemcan -->
									<v-flex xs12 md12 v-if="visiblerazoreemcan">
										<pui-text-area
											:id="`razoreemcan-notificacion`"
											v-model="model.razoreemcan"
											:label="$t('notificacion.razoreemcan')"
											disabled
											maxlength="350"
											toplabel
											:rows="2"
										></pui-text-area>
									</v-flex>
								</v-layout>
								<v-layout wrap>
									<!-- fechas reales -->
									<v-flex xs12 md12 v-if="model.fecentreal != null || model.fecsalreal != null">
										<pui-field-set :title="getTitleFechasReales">
											<v-layout wrap>
												<!-- FECHA ENTRADA REAL -->
												<v-flex xs12 md4 lg3>
													<pui-date-field
														:id="`fecentreal-notificacion`"
														:label="$t('notificacion.fecentreal')"
														v-model="model.fecentreal"
														toplabel
														time
														disabled
													></pui-date-field>
												</v-flex>
												<!-- FECHA SALIDA REAL -->
												<v-flex xs12 md4 lg3>
													<pui-date-field
														:id="`fecsalreal-notificacion`"
														:label="$t('notificacion.fecsalreal')"
														v-model="model.fecsalreal"
														toplabel
														time
														disabled
													></pui-date-field>
												</v-flex>
											</v-layout>
										</pui-field-set>
									</v-flex>
								</v-layout>
								<!-- MINI AUDIT -->
								<pui-form-mini-audit class="pl-2" :model="model" v-if="!isCreatingElement"></pui-form-mini-audit>
							</pui-field-set>
						</v-flex>
					</v-layout>
				</v-tab-item>

				<v-tab-item :key="1" lazy :value="'implicados'">
					<notificacion-form-tab-implicados :parentModel="model" :formDisabled="formDisabled"></notificacion-form-tab-implicados>
				</v-tab-item>

				<v-tab-item :key="2" lazy :value="'equipamientos'" v-if="!isCreatingElement && !isModalDialog">
					<pui-master-detail
						componentName="equipamiento-grid"
						:parentModelName="modelName"
						:parentPk="pk"
						:parentPkChildFk="{ idnotif: 'idnotif' }"
						:modalDialog="isModalDialog"
						:formDisabled="formDisabled"
					></pui-master-detail>
				</v-tab-item>

				<v-tab-item :key="3" lazy :value="'partidas'" v-if="!isCreatingElement && !isModalDialog">
					<pui-master-detail
						componentName="partida-grid"
						:parentModelName="modelName"
						:parentModel="model"
						:parentPk="pk"
						:parentPkChildFk="{ idnotif: 'idnotif' }"
						:modalDialog="isModalDialog"
						:formDisabled="formDisabled"
					></pui-master-detail>
				</v-tab-item>
				<v-tab-item :key="4" lazy :value="'documentos'" v-if="!isCreatingElement && !isModalDialog">
					<!-- <pui-master-detail componentName="puidocumentgrid" :parentModelName="modelName" :parentPk="pk"> </pui-master-detail> -->
					<pui-document-notificacion-grid componentName="puidocumentgrid" :parentModelName="modelName" :parentPk="pk" />
				</v-tab-item>
			</v-tabs-items>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import notificacionActions from './NotificacionActions';
import notificacionModals from './NotificacionModals.vue';
import notificacionFormTabImplicados from './NotificacionFormTabImplicados.vue';
import PuiDocumentNotificacionGrid from '../notificacionDocumento/PuiDocumentNotificacionGrid.vue';

export default {
	name: 'notificacion-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		'notificacion-modals': notificacionModals,
		notificacionFormTabImplicados: notificacionFormTabImplicados,
		'pui-document-notificacion-grid': PuiDocumentNotificacionGrid
	},
	data() {
		return {
			modelName: 'notificacion',
			tabmodel: 'maintab',
			actions: notificacionActions.formActions,
			escalanomcv: '',
			escalabandera: '',
			mostrarTerminal: null
		};
	},
	watch: {
		'model.periododescarga'() {
			if (this.modelLoaded) {
				if (!this.isEmpty(this.model.periododescarga)) {
					this.model.periodocarga = this.model.periododescarga;
				}
			}
		}
	},
	methods: {
		afterGetData() {
			const thisModel = this.$store.getters.getModelByName(this.modelName);
			if (thisModel) {
				if (thisModel.tabFromAction) {
					this.tabmodel = thisModel.tabFromAction;
					delete thisModel.tabFromAction;
				}
			}

			if (this.isCreatingElement) {
				this.model.manual = 'S';
			}

			if (this.model.numescent != null || this.model.numescsal != null) {
				this.getListamuelles();
			}
		},
		isEmpty(...values) {
			return values.some(
				value =>
					value === undefined ||
					value === null ||
					(typeof value === 'string' && value.trim() === '') ||
					(Array.isArray(value) && value.length === 0)
			);
		},
		getListamuelles() {
			var numescentMuelle = null;
			var numescsalMuelle = null;

			if (this.visibleLayoutEntradaMaritima) {
				numescentMuelle = this.model.numescent;
			}
			if (this.visibleLayoutSalida && this.visibleLayoutSalidaMaritima) {
				numescsalMuelle = this.model.numescsal;
			}

			const params = {
				numescent: numescentMuelle,
				numescsal: numescsalMuelle
			};

			const url = this.$store.getters.getModelByName('notificacion').url.getMuellesEscala;
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t('resolucion.getEscala.title');
			this.$puiRequests.getRequest(
				url,
				params,
				response => {
					this.$set(this.model, 'codpue', response.data.codpue);
					this.$set(this.model, 'listamuelles', response.data.muelles);
				},
				e => {
					// Error response, do code and not close modal
					let message = this.$puiI18n.t('puiaction.notifyError');
					if (e.response && e.response.data) {
						message = e.response.data.message;
					}
					this.$puiNotify.error(message, title);
				}
			);
		},
		watcherLupEscalaEntrada() {
			this.$puiEvents.$on(`onPuiSelect_selectedItems-wsescalaent`, newValEscala => {
				var lupamodel = newValEscala.model;
				if (!this.$appUtils.isEmpty(lupamodel)) {
					this.model.idbanbuqent = lupamodel.idbanbuq;
					this.model.banbuqent = lupamodel.codpaisbandera;

					this.model.nombuqueent = lupamodel.nombuque;
					this.model.omibuqent = lupamodel.imobuque;

					this.model.codpueescent = lupamodel.codpue;
					this.model.anyoescent = lupamodel.anyo;
					this.model.codescent = lupamodel.codescala;

					this.model.idpueantescent = lupamodel.idpueanterior;
					this.model.idpuesigescent = lupamodel.idpuesiguiente;

					this.model.pueantescent = lupamodel.locodepueanterior;
					this.model.puesigescent = lupamodel.locodepuesiguiente;

					//PUEESCENT, IDPUEESCENT
					this.model.puescent = lupamodel.puescent;
					this.model.idpuescent = lupamodel.idpuescent;

					this.model.nifcvescent = lupamodel.nifcv;
					this.model.doblecascoent = lupamodel.inddoblecasco !== null && lupamodel.inddoblecasco === '0' ? 'N' : 'S';
					if (lupamodel.eta) {
						this.model.fecentmer = lupamodel.eta + 'Z';
					}

					//tipo de manipulación 'tránsito permanece a bordo' copiar la información de la escala de entrada en la salida
					if (this.model.idtipoman === 3) {
						this.model.idbanbuqsal = lupamodel.idbanbuq;
						this.model.banbuqsal = lupamodel.codpaisbandera;

						this.model.nombuquesal = lupamodel.nombuque;
						this.model.omibuqsal = lupamodel.imobuque;

						this.model.codpueescsal = lupamodel.codpue;
						this.model.anyoescsal = lupamodel.anyo;
						this.model.codescsal = lupamodel.codescala;

						this.model.idpueantescsal = lupamodel.idpueanterior;
						this.model.idpuesigescsal = lupamodel.idpuesiguiente;
						this.model.pueantescsal = lupamodel.locodepueanterior;
						this.model.puesigescsal = lupamodel.locodepuesiguiente;

						this.model.nifcvescsal = lupamodel.nifcv;

						this.model.doblecascosal = lupamodel.inddoblecasco !== null && lupamodel.inddoblecasco === '0' ? 'N' : 'S';

						if (lupamodel.eta) {
							this.model.fecsalmer = lupamodel.eta + 'Z';
						}

						this.model.numescsal = this.model.numescent;
						this.escalabandera = lupamodel.bandera;
						this.escalanomcv = lupamodel.nomcv;
					}
					this.getListamuelles();
				}
			});
		},
		watcherLupEscalaSalida() {
			this.$puiEvents.$on(`onPuiSelect_selectedItems-wsescalasal`, newValEscala => {
				var lupamodel = newValEscala.model;
				if (!this.$appUtils.isEmpty(lupamodel)) {
					this.model.idbanbuqsal = lupamodel.idbanbuq;
					this.model.banbuqsal = lupamodel.codpaisbandera;

					this.model.nombuquesal = lupamodel.nombuque;
					this.model.omibuqsal = lupamodel.imobuque;

					this.model.codpueescsal = lupamodel.codpue;
					this.model.anyoescsal = lupamodel.anyo;
					this.model.codescsal = lupamodel.codescala;

					this.model.idpueantescsal = lupamodel.idpueanterior;
					this.model.idpuesigescsal = lupamodel.idpuesiguiente;

					this.model.pueantescsal = lupamodel.locodepueanterior;
					this.model.puesigescsal = lupamodel.locodepuesiguiente;

					this.model.nifcvescsal = lupamodel.nifcv;

					this.model.doblecascosal = lupamodel.inddoblecasco !== null && lupamodel.inddoblecasco === '0' ? 'N' : 'S';

					if (lupamodel.eta) {
						this.model.fecsalmer = lupamodel.eta + 'Z';
					}
					this.getListamuelles();
				}
			});
		},
		watcherLupAreaoperacion() {
			this.$puiEvents.$on(`onPuiSelect_selectedItems-notificacion-idareaopera`, newValArea => {
				var lupamodel = newValArea.model;
				if (!this.$appUtils.isEmpty(lupamodel)) {
					this.model.nomareaopera = lupamodel.name;
				}
			});
		},
		getTerminal() {
			const puiVariableUrl = '/puivariable/getVariable/MOSTRAR_TERMINAL';
			this.$puiRequests.getRequest(
				puiVariableUrl,
				null,
				response => {
					this.mostrarTerminal = response.data;
				},
				error => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
				}
			);
		}
	},
	computed: {
		getEscalaEntradaTextValue() {
			return this.model && this.model.numescent
				? this.model.numescent + ' - ' + this.model.nombuqueent + ' - ' + this.model.omibuqent + ' - ' + this.model.banbuqent
				: '-';
		},
		getTitleDatos() {
			return this.$t('form.notificacion');
		},
		getEscalaSalidaTextValue() {
			return this.model && this.model.numescsal
				? this.model.numescsal + ' - ' + this.model.nombuquesal + ' - ' + this.model.omibuqsal + ' - ' + this.model.banbuqsal
				: '-';
		},
		fixedFilterAreaopera() {
			const filter = {
				groups: [],
				groupOp: 'and',
				rules: []
			};
			if (!this.$appUtils.isEmpty(this.model.listamuelles)) {
				filter.rules.push({ field: 'codareaopera', op: 'in', data: this.model.listamuelles.map(muelle => muelle.codigo).join() });
			} else if (!this.$appUtils.isEmpty(this.model.idpuertolocal)) {
				filter.rules.push({ field: 'idpuertolocal', op: 'eq', data: this.model.idpuertolocal });
			}

			return filter;

			//return this.areaoperafilter;
		},
		fixedFilterTerminal() {
			return {
				groups: [
					{
						groupOp: 'and',
						rules: [{ field: 'fecbaja', op: 'eq', data: null }]
					}
				],
				groupOp: 'and',
				rules: []
			};
		},
		fixedFilterAgenteConsignatario() {
			return {
				groups: [
					{
						groupOp: 'and',
						rules: [{ field: 'puertolocal', op: 'eq', data: this.model.idpuertolocal }]
					}
				],
				groupOp: 'and',
				rules: []
			};
		},
		fixedFilterTransporteEntrada() {
			//Descarga, en tránsito, admision via marítima, admisión vía marítima(transbordo)
			if (this.model.idtipoman === 1 || this.model.idtipoman === 3 || this.model.idtipoman === 4 || this.model.idtipoman === 5) {
				return {
					groups: [
						{
							groupOp: 'and',
							rules: [{ field: 'codedi', op: 'eq', data: 1 }]
						}
					],
					groupOp: 'and',
					rules: []
				};
			}
			//Carga
			else if (this.model.idtipoman === 2) {
				return {
					groups: [
						{
							groupOp: 'or',
							rules: [
								{ field: 'codedi', op: 'eq', data: 7 },
								{ field: 'codedi', op: 'eq', data: 3 },
								{ field: 'codedi', op: 'eq', data: 2 }
							]
						}
					],
					groupOp: 'and',
					rules: []
				};
			}
			//Transito terrestre, entrada vía terrestre para su consumo dentro...
			else if (this.model.idtipoman === 6 || this.model.idtipoman === 9) {
				return {
					groups: [
						{
							groupOp: 'or',
							rules: [
								{ field: 'codedi', op: 'eq', data: 3 },
								{ field: 'codedi', op: 'eq', data: 2 }
							]
						}
					],
					groupOp: 'and',
					rules: []
				};
			}
			//Descarga de mercancías
			else if (this.model.idtipoman === 7) {
				return {
					groups: [
						{
							groupOp: 'and',
							rules: [{ field: 'codedi', op: 'eq', data: 3 }]
						}
					],
					groupOp: 'and',
					rules: []
				};
			}
			//Carga de mercancías
			else if (this.model.idtipoman === 8) {
				return {
					groups: [
						{
							groupOp: 'and',
							rules: [{ field: 'codedi', op: 'eq', data: 7 }]
						}
					],
					groupOp: 'and',
					rules: []
				};
			} else {
				return {};
			}
		},
		fixedFilterTransporteSalida() {
			//Descarga
			if (this.model.idtipoman === 1) {
				return {
					groups: [
						{
							groupOp: 'or',
							rules: [
								{ field: 'codedi', op: 'eq', data: 7 },
								{ field: 'codedi', op: 'eq', data: 3 },
								{ field: 'codedi', op: 'eq', data: 2 }
							]
						}
					],
					groupOp: 'and',
					rules: []
				};
			}
			//Carga, en tránsito, admisión vía marítima,admisión vía marítima(transbordo)
			else if (this.model.idtipoman === 2 || this.model.idtipoman === 3 || this.model.idtipoman === 4 || this.model.idtipoman === 5) {
				return {
					groups: [
						{
							groupOp: 'or',
							rules: [{ field: 'codedi', op: 'eq', data: 1 }]
						}
					],
					groupOp: 'and',
					rules: []
				};
			}
			//Transito terrestre
			else if (this.model.idtipoman === 6) {
				return {
					groups: [
						{
							groupOp: 'or',
							rules: [
								{ field: 'codedi', op: 'eq', data: 3 },
								{ field: 'codedi', op: 'eq', data: 2 }
							]
						}
					],
					groupOp: 'and',
					rules: []
				};
			}
			//Descarga de mercancías
			else if (this.model.idtipoman === 7) {
				return {
					groups: [
						{
							groupOp: 'and',
							rules: [{ field: 'codedi', op: 'eq', data: 7 }]
						}
					],
					groupOp: 'and',
					rules: []
				};
			}
			//Carga de mercancías
			else if (this.model.idtipoman === 8) {
				return {
					groups: [
						{
							groupOp: 'and',
							rules: [{ field: 'codedi', op: 'eq', data: 3 }]
						}
					],
					groupOp: 'and',
					rules: []
				};
			} else {
				return {};
			}
		},
		visibleLayoutEntradaTerrestre() {
			if (this.model.idtipotransent === 2 || this.model.idtipotransent === 3) {
				return true;
			}
			return false;
		},
		visibleLayoutEntradaMaritima() {
			if (this.model.idtipotransent === 1 || this.model.idtipotransent === 5) {
				return true;
			}
			return false;
		},
		visibleLayoutNomtransentInstfijas() {
			if (this.model.idtipotransent === 4) {
				return true;
			}
			return false;
		},
		visibleLayoutNomtranssalInstfijas() {
			if (this.model.idtipotranssal === 4) {
				return true;
			}
			return false;
		},
		visibleLayoutSalidaTerrestre() {
			if (this.model.idtipotranssal === 2 || this.model.idtipotranssal === 3) {
				return true;
			}
			return false;
		},
		visibleLayoutSalidaMaritima() {
			if (this.model.idtipotranssal === 1 || this.model.idtipotranssal === 5) {
				return true;
			}
			return false;
		},
		visibleLayoutWsescalasal() {
			//tipo de manipulación 'tránsito permanece a bordo' no visualizar el select
			if (this.model.idtipoman === 3) {
				return false;
			}
			return true;
		},
		visibleLayoutSalida() {
			if (this.model.idtipoman === 9) {
				return false;
			}
			return true;
		},
		fixedFilterwsescalaent() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'idpuertolocal', op: 'eq', data: this.model.idpuertolocal },
					{ field: 'eta', op: 'eq', data: this.model.fecnotpresentacion }
				]
			};
		},
		fixedFilterwsescalasal() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'idpuertolocal', op: 'eq', data: this.model.idpuertolocal },
					{ field: 'eta', op: 'eq', data: this.model.fecnotpresentacion }
				]
			};
		},
		requiredVehiculosNombreEntrada() {
			if (this.model.idtipotransent === 3) return true;
			return false;
		},
		requiredVehiculosNombreSalida() {
			if (this.model.idtipotranssal === 3) return true;
			return false;
		},
		visiblerazoreemcan() {
			if (this.model.idestado === 5 || this.model.idestado === 6) {
				//estado reemplazada o cancelada
				return true;
			}
			return false;
		},
		getTitleFechasReales() {
			return this.$t('form.notificacion.fechasreales');
		}
	},
	mounted() {
		this.getTerminal();
		this.watcherLupEscalaEntrada();
		this.watcherLupEscalaSalida();
		this.watcherLupAreaoperacion();
	},
	created() {}
};
</script>
